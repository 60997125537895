<template>
  <v-container class="ma-0 pa-1">
    <v-row no-gutters>
      <v-col align="left">
        <fake-button
          v-if="selected && gender=='MAN'"
          plain
          tile
          height="24px"
          btnClass="font-weight-black pa-0 ma-0 text-left"
          btnStyle="justify-content: left;"
          :text="'HLASOVAL SI ' + name"
          @click="unVote()"
        ></fake-button>
        <fake-button
          v-else-if="selected"
          plain
          tile
          height="24px"
          btnClass="font-weight-black pa-0 ma-0 text-left"
          btnStyle="justify-content: left;"
          :text="'HLASOVALA SI ' + name"
          @click="unVote()"
        ></fake-button>

        <fake-button
          v-else
          plain
          tile
          height="24px"
          btnClass="font-weight-regular pa-0 ma-0"
          btnStyle="justify-content: left;"
          :text="name"
          @click="vote()"
        ></fake-button>
      </v-col>
      <v-col
        class="text-body-2"
        align="right"
      >
        {{votes}} ({{ percentage }})%
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="pb-3"
    >
      <v-col>
        <v-progress-linear
          :color="color"
          height="15"
          :value="percentage"
          rounded
          style="cursor: pointer;"
          @click="voteProgress()"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog_auth"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_auth = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informácia

          </v-toolbar-title>

        </v-toolbar>

        <v-card-text class="pa-5">
          Nie si ZAREGISTROVANÝ/Á alebo PRIHLÁSENÝ/Á a preto nemôžeš využívať túto možnosť.
          Ak si zaregistrovaný/á, tak sa prihlás a ak nie si ešte zaregistrovaný/á, plne sa zaregistruj a over svoju identitu. Potom môžeš využívať aj túto funkciu. Registrácia je možná <a href="/register">TU</a>.
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog_bank"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_bank = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informácia

          </v-toolbar-title>

        </v-toolbar>

        <v-card-text class="pa-5">
          <p>Gratulujeme, si úspešne <span v-if="gender == 'MAN'">zaregistrovaný</span><span v-else>zaregistrovaná</span>.</p>

          <p>Ešte ostáva posledný krok. Overenie tvojej identity prostredníctvom platby sumy minimálne <strong>{{getSettings.hladina_volic}}.- €</strong> (môžeš aj viac ale od jedného eura a viac, sa počíta tvoje overenie ako platné a systémy hlasovania sa TI sprístupnia).
            Tebou zvolenú sumu pošli z tvojho bankového účtu, ktorý si <span v-if="gender == 'MAN'">uviedol</span><span v-else>uviedla</span> pri registrácií.</p>

          <p>Použi <strong>VS {{user_id}}</strong>. Úhradu vykonaj na IBAN <strong>SK4983300000004111441114</strong>.</p>

          <p>Potom už len čakáš na pripísanie tvojej platby na náš účet v banke. Zvyčajne to býva na druhý pracovný deň.
            Náš systém sa dopytuje bankového systému každých 5 minút a po informácií z banky, že si <span v-if="gender == 'MAN'">poslal</span><span v-else>poslala</span> požadovanú sumu na overenie tvojej identity spáruje túto platbu
            s tvojou osobou a okamžite sa Ti sprístupní táto funkcia.</p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
    
<script>
import { APIService } from "@/http/APIService.js";
import colors from 'vuetify/lib/util/colors';
import FakeButton from "../FakeButton.vue";
import { mapGetters } from "vuex";

const apiService = new APIService();


export default {
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    questionID: { type: Number, required: true },
    votes: { type: Number, required: true },
    totalVotes: { type: Number, required: true },
    color: { type: String, required: true },
    selected: { type: Boolean, required: false },
  },

  components: {
    FakeButton
  },

  data() {
    return {
      dialog_auth: false,
      dialog_bank: false
    }
  },

  events: {},

  watch: {
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

    percentage() {
      if (this.totalVotes == 0)
        return 0;

      return Math.round((this.votes + Number.EPSILON) / this.totalVotes * 100)
    }
  },

  mounted: function () {
  },

  methods: {
    translateColor(color) {
      if ('string' !== typeof color || color.trim().length == 0) {
        return color;
      }
      if (color.startsWith('#') || color.startsWith('rgb')) {
        return color;
      }
      const themeColors = Object.keys(this.$vuetify.theme.currentTheme);
      const themeColorIndex = themeColors.indexOf(color);
      if (themeColorIndex > -1) {
        return this.$vuetify.theme.currentTheme[color];
      }
      let baseColor;
      let shade;
      if (color.includes(' ')) {
        const [bc, s] = color.split(' ');
        baseColor = bc;
        shade = s;
      }
      else {
        baseColor = color;
        shade = 'base';
      }
      const generalColors = Object.keys(colors);
      const generalColorsIndex = generalColors.indexOf(baseColor);
      const themeColorsShadeIndex = themeColors.indexOf(baseColor);
      if (generalColorsIndex > -1) {
        const fixedShade = shade.toLowerCase().replace('-', '');
        const co = colors[generalColors[generalColorsIndex]];
        return co[fixedShade];
      }
      else if (themeColorsShadeIndex > -1) {
        const fixedShade = shade.toLowerCase().replace('-', '');
        const co = this.$vuetify.theme.parsedTheme[themeColors[themeColorsShadeIndex]]
        return co[fixedShade];
      }
      return color;
    },

    voteProgress() {
      if (!this.isAuthenticated)
        this.dialog_auth = true;
      else if (!this.isVerifiedSmsEmail)
        this.dialog_bank = true;
      else {
        if (this.selected)
          this.unVote();
        else
          this.vote();
      }
    },

    vote() {
      apiService.generalPollQuestionVote(this.questionID, this.type).then(() => {
        this.$emit("sync-child");
      });
    },
    unVote() {
      apiService.generalPollQuestionUnVote(this.questionID).then(() => {
        this.$emit("sync-child");
      });
    }

  },
};
</script>