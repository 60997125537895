<template>
  <v-container class="ma-0 pa-0">
    <v-row
      no-gutters
      justify="space-around"
    >
      <v-col
        cols="12"
        sm="3"
        class="ma-1 ma-sm-0 text-h5 pa-1"
        align="center"
        align-self="end"
        style="border: solid 1px rgb(76, 175, 80); border-radius: 5px;"
      >
        <span class="success--text">Verí tomu<br />{{ getPercentageYes() }}%</span>
      </v-col>

      <v-col
        cols="12"
        sm="5"
        class="ma-1 ma-sm-0  text-h5 pa-1"
        align="center"
        align-self="center"
        :style="`border: solid 1px ${getPercentageColor()}; border-radius: 5px;`"
      >
        <span
          v-if="question.hodnotenie == 0"
          class="primary--text text-h4"
        >REMÍZA</span>
        <span
          v-else-if="question.hodnotenie > 0"
          class="success--text"
        >Spolu tomu<br /><span class="text-h4">VERÍME</span><br />na {{ getPercentageAll() }}%</span>
        <span
          v-else
          class="error--text"
        >Spolu tomu<br /><span class="text-h4">NEVERÍME</span><br />na {{ Math.abs(getPercentageAll()) }}%</span>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="ma-1 ma-sm-0 text-h5 pa-1"
        align="center"
        align-self="end"
        style="border: solid 1px rgb(183, 28, 28); border-radius: 5px;"
      >
        <span class="error--text">Neverí tomu<br />{{ getPercentageNo() }}%</span>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      v-show="zdroje"
    >
      <v-col align="left">
        <span v-html="question.sources"></span>
      </v-col>
    </v-row>
  </v-container>
</template>
      
<script>
import Vue from "vue";
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

Vue.use(require("vue-moment"));

export default {
  props: {
    question: { type: Object, required: true },
  },

  data() {
    return {
      zdroje: false,
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
    getPercentageColor() {
      if (this.getPercentageAll() == 0) {
        return '#3f51b5';
      } else if(this.getPercentageAll() < 0) {
        return 'rgb(183, 28, 28)';
      } else {
        return 'rgb(76, 175, 80)';
      }
    },
    getVotesWithoutNeutral() {
      return this.question.votes_yes_yes_count + 0.5 * this.question.votes_yes_no_count + this.question.votes_no_no_count + 0.5 * this.question.votes_no_yes_count;
    },

    getPercentageAll() {
      let tmp = (this.question.votes_yes_yes_count + 0.5 * this.question.votes_yes_no_count - this.question.votes_no_no_count - 0.5 * this.question.votes_no_yes_count) / this.getVotesWithoutNeutral() * 100;
      return Math.round((tmp ? tmp : 0 + Number.EPSILON) * 100) / 100;
    },
    getPercentageYes() {
      let tmp = (this.question.votes_yes_yes_count + 0.5 * this.question.votes_yes_no_count) / this.getVotesWithoutNeutral() * 100;
      return Math.round((tmp ? tmp : 0 + Number.EPSILON) * 100) / 100;
    },
    getPercentageNo() {
      let tmp = (this.question.votes_no_no_count + 0.5 * this.question.votes_no_yes_count) / this.getVotesWithoutNeutral() * 100;
      return Math.round((tmp ? tmp : 0 + Number.EPSILON) * 100) / 100;
    }
  },
};
</script>